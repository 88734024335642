import { template as template_cfadcd6ab4bd42a1a71d51cc6c4bd857 } from "@ember/template-compiler";
const FKText = template_cfadcd6ab4bd42a1a71d51cc6c4bd857(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
