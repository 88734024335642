import { template as template_980467f128604171989e156e5073998d } from "@ember/template-compiler";
const FKLabel = template_980467f128604171989e156e5073998d(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
