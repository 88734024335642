import { template as template_0f121d307b12435798fb89b457740f72 } from "@ember/template-compiler";
const FKControlMenuContainer = template_0f121d307b12435798fb89b457740f72(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
