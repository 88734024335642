import { template as template_51b70fa2affc48d09c44844f38a3f02c } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_51b70fa2affc48d09c44844f38a3f02c(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
