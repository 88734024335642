import { template as template_6ce50f2f3c1c432e927b689e44b1f17b } from "@ember/template-compiler";
const SidebarSectionMessage = template_6ce50f2f3c1c432e927b689e44b1f17b(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
